<template>
  <div class="register pb-5">
    <div class="container-lg">
      <div class="row mb-3">
        <div class="col-sm-11 mx-auto">
          <img src="@/assets/img/register/reg-banner.jpg" class="img-fluid" alt="registration banner" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-11 mx-auto">
          <p>
            Join us at PDP Week as we explore the vital intersection between
            data protection and technology. Start with the
            <strong>flagship PDP Seminar</strong> and a suite of workshops,
            followed by the
            <strong>Privacy-Enhancing Technology Summit Asia-Pacific 2024</strong>
            and the <strong>IAPP Asia Privacy Forum 2024.</strong>
          </p>
          <p>
            For more information on PDP Week 2024, please visit
            <a href="https://go.gov.sg/pdpweek2024" target="_blank">https://go.gov.sg/pdpweek2024</a>
          </p>
          <div class="row">
            <div class="col-12">
              <p class="font-italic text-danger">*Mandatory fields</p>
            </div>
            <div class="col-sm-2">
              <div class="form-group">
                <label>Salutation<span class="text-danger">*</span></label>
                <select name="salutation" class="form-control" v-model="participant.salutation"
                  @blur="handleSalutation">
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Dr ">Dr</option>
                  <option value="Prof ">Prof</option>
                  <option value="Assoc Prof">Assoc Prof</option>
                  <option value="Others">Others</option>
                </select>
                <span class="text-danger" v-for="message of validation_message.salutation"
                  :key="`salutation-${message}`">{{ message }}<br /></span>
              </div>
            </div>
            <div class="col-sm-12" v-if="participant.salutation == 'Others'">
              <div class="form-group">
                <label>Other Salutation (Please specify)<span class="text-danger">*</span></label>
                <input type="text" name="salutation_other" id="salutation_other" v-model="participant.salutation_others"
                  class="form-control" />
                <span class="text-danger" v-for="message of validation_message.salutation_others"
                  :key="`salutation_others-${message}`">{{ message }}<br /></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>First Name<span class="text-danger">*</span></label>
                <input type="text" name="fname" id="fname" class="form-control" @blur="handleFirstName"
                  v-model="participant.first_name" />
                <span class="text-danger" v-for="message of validation_message.first_name"
                  :key="`first_name-${message}`">{{ message }}<br /></span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Last Name<span class="text-danger">*</span></label>
                <input type="text" name="lname" id="lname" class="form-control" @blur="handleLastName"
                  v-model="participant.last_name" />
                <span class="text-danger" v-for="message of validation_message.last_name"
                  :key="`last_name-${message}`">{{ message }}<br /></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>Email<span class="text-danger">*</span></label>
                <input type="email" name="email" class="form-control" placeholder="Eg. john@gmail.com"
                  @blur="handleEmail" v-model="participant.email" />
                <span class="text-danger" v-for="message of validation_message.email" :key="`email-${message}`">{{
                  message }}<br /></span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Confirm your email address<span class="text-danger">*</span></label>
                <input type="email" name="reconfirm-email" class="form-control" placeholder="Confirm your email address"
                  v-model="participant.email_confirmation" @blur="handleConfirmEmail" />
                <span class="text-danger" v-for="message of validation_message.email_confirmation"
                  :key="`email_confirmation-${message}`">{{ message }}<br /></span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Job Title<span class="text-danger">*</span></label>
                <input type="text" name="lname" class="form-control" placeholder="" @blur="handleJobTitle"
                  v-model="participant.job_title" />
                <span class="text-danger" v-for="message of validation_message.job_title"
                  :key="`job_title-${message}`">{{ message }}<br /></span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Job Levelling<span class="text-danger">*</span></label>
                <select name="job_level" class="form-control" v-model="participant.job_level" @blur="handleJobLevel">
                  <option value="academia">Academia</option>
                  <option value="analyst">Analyst</option>
                  <option value="board_chairman">Board Chairman</option>
                  <option value="c_level">C-Level (CEO/President)</option>
                  <option value="consultant_advisor">Consultant/Advisor</option>
                  <option value="executive_director_md_gm">
                    Executive Director/ Managing Director/ General Manager
                  </option>
                  <option value="founder_cofounder">Founder/ Co-founder</option>
                  <option value="gov_head_of_agency">
                    Government: Head of Agency
                  </option>
                  <option value="gov_officials">Government: Officials</option>
                  <option value="gov_minister">Government: Minister</option>
                  <option value="head_of_department_director">
                    Head of Department/ Director
                  </option>
                  <option value="head_of_mission_diplomatic_corp">
                    Head of Mission/ Diplomatic Corp
                  </option>
                  <option value="manager">Manager</option>
                  <option value="evp_svp_vp_cxo">EVP/SVP/VP/CxO</option>
                  <option value="researcher">Researcher</option>
                  <option value="student">Student</option>
                  <option value="others">Others</option>
                </select>
                <span class="text-danger" v-for="message of validation_message.job_level"
                  :key="`job_level-${message}`">{{ message }}<br /></span>
              </div>
            </div>
            <div class="col-sm-6" v-if="participant.job_level == 'others'">
              <div class="form-group">
                <label>Other Job Levelling (Please specify)<span class="text-danger">*</span></label>
                <input type="text" name="job_level_other" id="job_level_other" v-model="participant.job_level_others"
                  class="form-control" />
                <span class="text-danger" v-for="message of validation_message.job_level_others"
                  :key="`job_level_others-${message}`">{{ message }}<br /></span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Organisation Name<span class="text-danger">*</span></label>
                <input type="text" name="oname" class="form-control" @blur="handleOrganisation"
                  v-model="participant.organization" />
                <span class="text-danger" v-for="message of validation_message.organization"
                  :key="`organization-${message}`">{{ message }}<br /></span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Organisation Business Nature<span class="text-danger">*</span></label>
                <select name="organization_nature" class="form-control" v-model="participant.organization_nature"
                  @blur="handleOrgNature">
                  <option value="academia_think_tank">
                    Academia/Think Tank
                  </option>
                  <option value="accommodation_food_services">
                    Accommodation and Food Services
                  </option>
                  <option value="admin_support_services">
                    Admin and Support Services
                  </option>
                  <option value="arts_entertainment_recreation">
                    Arts, Entertainment and Recreation
                  </option>
                  <option value="consultancy_professional_services">
                    Consultancy and Professional Services
                  </option>
                  <option value="education">Education</option>
                  <option value="banking_finance_insurance">
                    Banking, Finance and Insurance
                  </option>
                  <option value="government_regulatory_agencies">
                    Government and Regulatory Agencies
                  </option>
                  <option value="healthcare">Healthcare</option>
                  <option value="ict">
                    Information and Communications Technology
                  </option>
                  <option value="international_organisations">
                    International Organisations
                  </option>
                  <option value="legal_profession">Legal Profession</option>
                  <option value="manufacturing">Manufacturing</option>
                  <option value="media_telecommunications">
                    Media and Telecommunications
                  </option>
                  <option value="pharmaceutical_biotechnology">
                    Pharmaceutical & Biotechnology
                  </option>
                  <option value="real_estate">Real Estate</option>
                  <option value="research_development">
                    Research & Development
                  </option>
                  <option value="retail_consumer_businesses">
                    Retail and Consumer Businesses
                  </option>
                  <option value="social_services">
                    Social Services (i.e. Non-Profit Organisations)
                  </option>
                  <option value="trade_associations">Trade Associations</option>
                  <option value="transportation_storage">
                    Transportation and Storage
                  </option>
                  <option value="others">Others</option>
                </select>
                <span class="text-danger" v-for="message of validation_message.organization_nature"
                  :key="`organization_nature-${message}`">{{ message }}<br /></span>
              </div>
            </div>
            <div class="col-sm-6" v-if="participant.organization_nature == 'others'">
              <div class="form-group">
                <label>Other Organisation Business Nature (Please specify)<span class="text-danger">*</span></label>
                <input type="text" name="organization_nature_other" id="organization_nature_other"
                  v-model="participant.organization_nature_others" class="form-control" />
                <span class="text-danger" v-for="message of validation_message.organization_nature_others"
                  :key="`organization_nature_others-${message}`">{{ message }}<br /></span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Organisation Size<span class="text-danger">*</span></label>
                <select name="organization_size" class="form-control" v-model="participant.organization_size"
                  @blur="handleOrgSize">
                  1,000 - 4,999

                  <option value="5,000 and more">5,000 and more</option>
                  <option value="1,000 - 4,999">1,000 - 4,999</option>
                  <option value="500 - 999">500 - 999</option>
                  <option value="200 - 499">200 - 499</option>
                  <option value="50 - 199">50 - 199</option>
                  <option value="1 - 49">1 - 49</option>
                </select>
                <span class="text-danger" v-for="message of validation_message.organization_size"
                  :key="`organization_size-${message}`">{{ message }}<br /></span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Country / Region (Based in)<span class="text-danger">*</span></label>
                <select class="form-control" name="country" v-model="participant.country" required
                  @blur="handleCountry">
                  <option value="Singapore">Singapore</option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Åland Islands">Åland Islands</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antarctica">Antarctica</option>
                  <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia & Herzegovina">
                    Bosnia & Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </option>
                  <option value="British Virgin Islands">
                    British Virgin Islands
                  </option>
                  <option value="Brunei">Brunei</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Caribbean Netherlands">
                    Caribbean Netherlands
                  </option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos (Keeling) Islands">
                    Cocos (Keeling) Islands
                  </option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo - Brazzaville">
                    Congo - Brazzaville
                  </option>
                  <option value="Congo - Kinshasa">Congo - Kinshasa</option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Côte d’Ivoire">Côte d’Ivoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Curaçao">Curaçao</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czechia">Czechia</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Eswatini">Eswatini</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands">Falkland Islands</option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Territories">
                    French Southern Territories
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guernsey">Guernsey</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-Bissau">Guinea-Bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard & McDonald Islands">
                    Heard & McDonald Islands
                  </option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong SAR China">
                    Hong Kong SAR China
                  </option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran">Iran</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jersey">Jersey</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Laos">Laos</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libya">Libya</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macao SAR China">Macao SAR China</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia">Micronesia</option>
                  <option value="Moldova">Moldova</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar (Burma)">Myanmar (Burma)</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="North Korea">North Korea</option>
                  <option value="North Macedonia">North Macedonia</option>
                  <option value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestinian Territories">
                    Palestinian Territories
                  </option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn Islands">Pitcairn Islands</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Réunion">Réunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russia">Russia</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="São Tomé & Príncipe">
                    São Tomé & Príncipe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Sint Maarten">Sint Maarten</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia & South Sandwich Islands">
                    South Georgia & South Sandwich Islands
                  </option>
                  <option value="South Korea">South Korea</option>
                  <option value="South Sudan">South Sudan</option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="St. Barthélemy">St. Barthélemy</option>
                  <option value="St. Helena">St. Helena</option>
                  <option value="St. Kitts & Nevis">St. Kitts & Nevis</option>
                  <option value="St. Lucia">St. Lucia</option>
                  <option value="St. Martin">St. Martin</option>
                  <option value="St. Pierre & Miquelon">
                    St. Pierre & Miquelon
                  </option>
                  <option value="St. Vincent & Grenadines">
                    St. Vincent & Grenadines
                  </option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard & Jan Mayen">
                    Svalbard & Jan Mayen
                  </option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syria">Syria</option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania">Tanzania</option>
                  <option value="Thailand">Thailand</option>
                  <option value="Timor-Leste">Timor-Leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks & Caicos Islands">
                    Turks & Caicos Islands
                  </option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="U.S. Outlying Islands">
                    U.S. Outlying Islands
                  </option>
                  <option value="U.S. Virgin Islands">
                    U.S. Virgin Islands
                  </option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Vatican City">Vatican City</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Wallis & Futuna">Wallis & Futuna</option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>
                <span class="text-danger" v-for="message of validation_message.country" :key="`country-${message}`">{{
                  message }}<br /></span>
              </div>
            </div>


          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-11 mx-auto">
          <div class="sessions mt-4">
            <div class="session mb-5" v-if="!loading_limit" id="session_all">
              <div class="card card-body p-2 px-4 bg-primary text-white text-uppercase b-600 mb-4">
                Monday, 15 July 2024
              </div>
              <p class="text-danger">{{ selectanyone }}</p>
              <!-- pdp seminar -->
              <div class="row justify-content-between mb-2 py-2" :class="{ 'bg-info': !limits.pdp_week }">
                <div class="col-sm-3">
                  <input value="1" disabled v-model="participant.pdp_week" type="checkbox" id="pdp_week" />
                  <label for="pdp_week">9.30am - 12.00pm</label>
                </div>
                <div class="col-sm-4">
                  <p class="mb-1"><b>PDP Seminar </b></p>
                  <p class="mb-0 font-italic">
                    For data protection officers, business owners, chief data
                    officers and AI/data product developers.
                  </p>
                  <span class="text-danger mt-2 font-italic" v-if="!limits.pdp_week">*Session is full. Please check the
                    box if you wish to join
                    the waitlist.<br /></span>

                  <span class="text-danger mt-2" v-for="message of validation_message.pdp_week"
                    :key="`pdp_week-${message}`">{{ message }}<br /></span>
                </div>
                <div class="col-sm-2 b-700 px-sm-0">Fee: Complimentary</div>
              </div>
              <!-- end pdp seminar -->
              <div class="card card-body p-2 px-4 bg-primary text-white text-uppercase b-600 mb-4">
                Tuesday, 16 July 2024
              </div>
              <!--  privacy_enhancing_technology_summit -->

              <div class="row mb-2 py-2 justify-content-between" :class="{
                'bg-info': !limits.privacy_enhancing_technology_summit,
              }">
                <div class="col-sm-3">
                  <input value="1" disabled v-model="participant.privacy_enhancing_technology_summit" type="checkbox"
                    id="privacy_enhancing_technology_summit" />
                  <label for="privacy_enhancing_technology_summit">9.00am - 12.00pm</label>
                </div>
                <div class="col-sm-4">
                  <p class="mb-2">
                    <b>Privacy-Enhancing Technology Summit Asia-pacific 2024<br />
                      <span class="font-weight-normal"></span>
                    </b>
                  </p>
                  <p class="mb-0 font-italic">
                    For data protection officers, business users, chief data officers, AI/data product developers and
                    their technical teams.
                  </p>
                  <span class="text-danger mt-2 font-italic" v-if="!limits.privacy_enhancing_technology_summit">*Session
                    is full. Please check the box if you wish to join
                    the waitlist.<br /></span>
                  <span class="text-danger mt-2"
                    v-for="message of validation_message.privacy_enhancing_technology_summit"
                    :key="`privacy_enhancing_technology_summit-${message}`">{{ message }}<br /></span>
                </div>
                <div class="col-sm-2 b-700 px-sm-0">Fee: Complimentary</div>
              </div>

              <!-- end privacy_enhancing_technology_summit -->

              <!-- for Singapore OECD -->

              <div class="row mb-2 py-2 justify-content-between" :class="{ 'bg-info': !limits.sg_oecd_workshop }">
                <div class="col-sm-3">
                  <input value="1" disabled v-model="participant.sg_oecd_workshop" type="checkbox"
                    id="sg_oecd_workshop" />
                  <label for="sg_oecd_workshop">1.30pm - 6.00pm</label>
                </div>
                <div class="col-sm-4">
                  <p class="mb-2">
                    <b>Singapore-OECD Expert Workshop on Privacy Enhancing Technology<br />
                      <span class="font-weight-normal"></span>
                    </b>
                  </p>
                  <p class="mb-0 font-italic">
                    For data protection authorities, enterprises and academia.
                  </p>
                  <span class="text-danger mt-2 font-italic" v-if="!limits.sg_oecd_workshop">*Session is
                    full. Please check the
                    box if you wish to join
                    the waitlist.<br /></span>
                  <span class="text-danger mt-2" v-for="message of validation_message.sg_oecd_workshop"
                    :key="`sg_oecd_workshop-${message}`">{{ message }}<br /></span>
                </div>
                <div class="col-sm-2 b-700 px-sm-0">Fee: Complimentary</div>

              </div>

              <!-- end Singapore OECD -->

            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-sm-11 mx-auto">
          <p>Please note:</p>

          <ol>
            <li>There's limited capacity for all sessions.</li>
            <li>Registration is on first-come, first-served basis.</li>
            <li>
              PDPC and its partners reserve the rights on the acceptance and
              confirmation of registrants for the respective sessions.
            </li>


          </ol>
          <p>
            For further enquiries, you can reach the event secretariat at
            <b><a href="mailto:pdp.enquiries@pico.com">pdp.enquiries@pico.com</a></b>
            or
            <a href="https://wa.me/6585733958" target="_blank" class="font-weight-bold">+65 8573 3958</a>. We are online
            from 9.00am to 6.00pm, Monday to Friday, and we
            will try to respond to your email within 1 working day.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-11 mx-auto">
          <div class="form-group form-check px-0">
            <p class="font-weight-bold">
              TERMS & CONDITIONS:<span class="text-danger">*</span>
              <span v-if="!terms_checkbox && showError" class="text-danger font-weight-normal ml-1">Please accept the
                Terms & Conditions.</span>
            </p>

            <p>
              The registrant consents to the use and disclose of his/her
              personal data provided in this form by the organisers, and its
              agents/partners/authorised service providers for the purposes of
              the abovementioned sessions and for communicating relevant
              information about the organisers, including their programmes and
              services.
            </p>

            <p>
              Photos/Recordings will be taken during the event for news and
              publicity purposes.
            </p>
            <input type="checkbox" class="form-check-input" id="Check1" v-model="terms_checkbox" />
            <label class="form-check-label font-weight-normal" for="Check1" style="font-size: 16px">
              I agree to the Terms and Conditions, and Personal Data Policy of
              the organisers of PDP Week 2024.
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 mx-auto">
          <button class="btn btn-primary btn-lg btn-block text-uppercase b-600" @click="store()" v-if="!loading">
            Submit
          </button>
          <button class="btn btn-primary reg-btn btn-lg btn-block b-700" disabled v-if="loading">
            Please wait ...
          </button>
        </div>
      </div>
    </div>
    <!-- success modal -->
    <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center p-5">
            <div class="row justify-content-center mb-4">
              <div class="col-2">
                <img src="@/assets/img/register/succesicon.png" class="img-fluid" alt="success icon" />
              </div>
            </div>
            <h1 class="mb-3" v-if="!already_rejected">Registration Complete</h1>
            <h1 class="mb-3" v-else>Already Registration</h1>

            <p class="text-cener" v-if="!already_rejected">
              Thank you for registering for PDP Week 2024. An email confirmation
              will be sent to you shortly. Please contact us at
              <a href="mailto:pdp.enquiries@pico.com">pdp.enquiries@pico.com</a>
              if you do not receive the email within the next hour.
            </p>
            <p class="text-cener" v-else>
              Your Have already submitted register request
            </p>
          </div>
        </div>
      </div>
    </div>



    <!-- failed modal -->
    <div class="modal fade" id="failedModal" tabindex="-1" aria-labelledby="failedModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center p-5">
            <div class="row justify-content-center mb-4">
              <div class="col-2">
                <img src="@/assets/img/register/error.png" class="img-fluid" alt="success icon" />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-8 mx-auto text-left">
                <p class="h5 mb-2">Please complete the required fields.</p>
                <span class="text-danger">{{
                  `&#x2022; ${selectanyone}`
                }}</span>
                <span v-if="!terms_checkbox && showError" class="text-danger">&#x2022; Please accept the Terms &
                  Conditions.</span>
                <div class="error-wrapper" v-for="(err, index) in Object.keys(validation_message)">
                  <span class="text-danger" v-for="message of validation_message[err]" :key="`${err}-${message}`">{{
                    `&#x2022; ${message}` }}<br /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- WAITLIST -->
    <div class="modal fade" id="waitListModal" tabindex="-1" aria-labelledby="waitListModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center p-5">
            <div class="row justify-content-center mb-4">
              <div class="col-2">
                <img src="@/assets/img/register/succesicon.png" class="img-fluid" alt="success icon" />
              </div>
            </div>
            <h1 class="mb-3">Waitlist Request Submitted</h1>
            <p class="text-cener">
              Thank you for your interest in PDP Week 2024. An acknowledgement
              email will be sent to you shortly. Please contact us at
              <a href="mailto:pdp.enquiries@pico.com">pdp.enquiries@pico.com</a>
              if you do not receive the email within the next hour.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Csrf from "@/assets/js/Csrf";
import Api from "@/assets/js/Api";
import $ from "jquery";
export default {
  name: "register",
  mounted() {
    if (this.$route.query.utm_source) {
      // console.log("this.$route.query.utm_source", this.$route.query.utm_source);
      this.participant.utm_source = this.$route.query.utm_source;
    }

    Api.get("/get-closed-sessions-limit").then((res) => {
      console.log("data", res);
      this.loading_limit = 0;

      this.limits = res.data.limit;
    });
  },
  data() {
    return {
      already_rejected: false,
      loading_limit: 0,
      disable: "",
      loading: false,
      terms_checkbox: false,
      showError: false,
      participant: {
        utm_source: "",
        salutation: "",
        salutation_other: "",
        first_name: "",
        last_name: "",
        email: "",
        email_confirmation: "",
        phone: "",
        job_title: "",
        job_level: "",
        job_level_other: "",
        organization: "",
        organization_nature: "",
        organization_nature_other: "",
        organization_size: "",
        country: "",
        dpo: "--",
        pdp_week: true,
        sg_oecd_workshop: true,
        privacy_enhancing_technology_summit: true,

      },
      validation_message: {
        salutation: [],
        salutation_other: [],
        first_name: [],
        last_name: [],
        email: [],
        email_confirmation: [],
        phone: [],
        job_title: [],
        job_level: [],
        job_level_other: [],
        organization: [],
        organization_nature: [],
        organization_nature_other: [],
        organization_size: [],
        country: [],
        dpo: [],

        pdp_week: [],
        sg_oecd_workshop: [],
        privacy_enhancing_technology_summit: [],

      },

      capacity_message: {
        pdp_limit: "",
        sg_oecd_workshop: "",
        privacy_enhancing_technology_summit: "",
      },
      selectanyone: "",
      selected_session_count: 0,
      limits: {},
      isEmailValid: false,
    };
  },
  watch: {
    participant: {
      handler(newValue, oldValue) {
        this.sametimesession();
      },
      deep: true,
    },
  },
  methods: {
    handleSalutation(e) {
      if (this.participant.salutation == "") {
        this.validation_message.salutation = ["This field is required."];
      } else {
        this.validation_message.salutation = [];
      }
    },
    handleEmail(e) {
      if (this.participant.email == "") {
        this.validation_message.email = ["This field is required."];
      } else if (!this.validateEmail(this.participant.email)) {
        this.validation_message.email = [
          "The email must be a valid email address.",
        ];
      } else {
        this.validation_message.email = [];
      }
    },
    handleLastName(e) {
      console.log(
        "this.participant.last_name.length",
        this.participant.last_name.length
      );
      if (this.participant.last_name == "") {
        this.validation_message.last_name = ["This field is required."];
      } else if (this.participant.last_name.length < 2) {
        this.validation_message.last_name = [
          "The last name must be at least 2 character long.",
        ];
      } else {
        this.validation_message.last_name = [];
      }
    },
    handleFirstName(e) {
      if (this.participant.first_name == "") {
        this.validation_message.first_name = ["This field is required."];
      } else if (this.participant.first_name.length < 2) {
        this.validation_message.first_name = [
          "The last name must be at least 2 character long.",
        ];
      } else {
        this.validation_message.first_name = [];
      }
    },
    handleJobLevel(e) {
      if (this.participant.job_level == "") {
        this.validation_message.job_level = ["This field is required."];
      } else {
        this.validation_message.job_level = [];
      }
    },
    handleOrgNature(e) {
      if (this.participant.organization_nature == "") {
        this.validation_message.organization_nature = [
          "This field is required.",
        ];
      } else {
        this.validation_message.organization_nature = [];
      }
    },
    handleOrgSize(e) {
      if (this.participant.organization_size == "") {
        this.validation_message.organization_size = ["This field is required."];
      } else {
        this.validation_message.organization_size = [];
      }
    },
    handleCountry(e) {
      if (this.participant.country == "") {
        this.validation_message.country = ["This field is required."];
      } else {
        this.validation_message.country = [];
      }
    },
    handleDpo(e) {
      if (this.participant.dpo == "") {
        this.validation_message.dpo = ["This field is required."];
      } else {
        this.validation_message.dpo = [];
      }
    },

    handleOrganisation(e) {
      if (this.participant.organization == "") {
        this.validation_message.organization = ["This field is required."];
      } else {
        this.validation_message.organization = [];
      }
    },
    handlePhone(e) { },
    handleJobTitle(e) {
      if (this.participant.job_title == "") {
        this.validation_message.job_title = ["This field is required."];
      } else {
        this.validation_message.job_title = [];
      }
    },

    handleConfirmEmail(e) {
      if (this.participant.email_confirmation == "") {
        this.validation_message.email_confirmation = [
          "This field is required.",
        ];
      } else if (
        this.participant.email_confirmation != this.participant.email
      ) {
        this.validation_message.email_confirmation = [
          "This email does not match.",
        ];
      } else {
        this.validation_message.email_confirmation = [];
      }
    },
    validateEmail(email) {
      // Email validation using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    customVal() {
      this.handleEmail({});
      this.handleLastName({});
      this.handleFirstName({});
      this.handleJobLevel({});
      this.handleOrganisation({});
      this.handleConfirmEmail({});
      this.handleJobTitle({});
      if (this.participant.job_level == "") {
        this.validation_message.job_level = ["This field is required."];
      } else {
        this.validation_message.job_level = [];
      }

      if (this.participant.salutation == "") {
        this.validation_message.salutation = ["This field is required."];
      } else {
        this.validation_message.salutation = [];
      }

      if (this.participant.organization_nature == "") {
        this.validation_message.organization_nature = [
          "This field is required.",
        ];
      } else {
        this.validation_message.organization_nature = [];
      }

      if (this.participant.organization_size == "") {
        this.validation_message.organization_size = ["This field is required."];
      } else {
        this.validation_message.organization_size = [];
      }

      if (this.participant.country == "") {
        this.validation_message.country = ["This field is required."];
      } else {
        this.validation_message.country = [];
      }

      // if (this.participant.dpo == "") {
      //   this.validation_message.dpo = ["This field is required."];
      // } else {
      //   this.validation_message.dpo = [];
      // }
    },
    store() {

      this.selectanyone = "";
      this.loading = true;
      let errorFlag = false;
      this.resetValidation();
      this.resetcapacitymessage();

      if (!this.terms_checkbox) {
        this.showError = true;
        this.loading = false;
        errorFlag = true;
        // $("#failedModal").modal("show");
      } else {
        this.showError = false;
      }

      if (
        this.participant.pdp_week == 0 &&
        this.participant.sg_oecd_workshop == 0 &&
        this.participant.privacy_enhancing_technology_summit == 0
      ) {
        this.selectanyone = "Please select at least one session.";
        console.log(this.participant);
        this.loading = false;
        // $("#failedModal").modal("show");
        if (!errorFlag) {
          document.getElementById("session_all").scrollIntoView({
            behavior: "smooth",
          });
        }
        errorFlag = true;
      }

      if (errorFlag) {
        this.customVal();
        this.loading = false;

        return;
      }

      // console.table(this.participant);
      // return;
      Api.post("closed-door-participant", this.participant).then((res) => {

        if (res.data.validation_error) {

          if (res.data.already_rejected) {
            this.already_rejected = true;
            this.successModal();
            return;
          }
          this.validation_message = res.data.validation_message;
          this.loading = false;
          // console.log(
          //   res.data.validation_message,

          // );
          if (
            res.data.validation_message.privacy_enhancing_technology_summit ||
            res.data.validation_message.sg_oecd_workshop ||
            res.data.validation_message.pdp_week
          ) {
            document.getElementById("session_all").scrollIntoView({
              behavior: "smooth",
            });
          } else if (Object.keys(res.data.validation_message).length > 4) {
            console.log(res.data.validation_message);
            $(window).scrollTop(0);
          }

          else {
            $(window).scrollTop(0);
          }

          // $("#failedModal").modal("show");

          return;
        }

        if (res.data.capacity_error) {
          this.capacity_message = res.data.capacity_message;
          this.loading = false;
          document.getElementById("session_all").scrollIntoView({
            behavior: "smooth",
          });
          return;
        }
        if (res.data.stripe_url) {
          location.href = res.data.stripe_url;
          return;
        }
        this.successModal(res.data.waitlist_only);
        this.resetValidation();
        this.resetcapacitymessage();
      });
    },
    successModal(data) {
      this.terms_checkbox = false;
      this.participant = {
        utm_source: "",
        salutation: "",
        salutation_other: "",
        first_name: "",
        last_name: "",
        email: "",
        email_confirmation: "",
        phone: "",
        job_title: "",
        job_level: "",
        job_level_other: "",
        organization: "",
        organization_nature: "",
        organization_nature_other: "",
        organization_size: "",
        country: "",
        dpo: "--",
        pdp_week: true,
        sg_oecd_workshop: true,
        privacy_enhancing_technology_summit: true,

      };
      this.loading = false;
      this.selectanyone = "";
      this.selected_session_count = 0;
      if (data) $("#waitListModal").modal("show");
      else $("#successModal").modal("show");
    },
    resetValidation() {
      this.validation_message = {
        frst_name: "",
        last_name: "",
        email: "",
        email_confirmation: "",
        salutation: "",
        industry: "",
        occupation: "",
        organization: "",
        designation: "",
        organization_sme: "",
        dpo: "",
        industry_others: "",
        phone: "",
      };
    },
    resetcapacitymessage() {
      this.capacity_message = {
        pdp_limit: "",
        th_data_soverignty_session: "",
        virtual_roadahead_session: "",
        fr_dpo_session: "",
        fr_navigating_breach_session: "",
      };
    },
    sametimesession() {
      if (
        !this.participant.fr_dpo_session &&
        !this.participant.fr_navigating_breach_session
      ) {
        this.disable = "";
      }

      if (this.participant.fr_dpo_session) {
        this.disable = "fr_navigating_breach_session";
      }

      if (this.participant.fr_navigating_breach_session) {
        this.disable = "fr_dpo_session";
      }
    },
    // CheckSessionCount() {
    //   this.selected_session_count = 0;

    //   if (this.participant.pdp_seminar_session) {
    //     this.selected_session_count++;
    //   }
    //   if (this.participant.th_data_soverignty_session) {
    //     this.selected_session_count++;
    //   }
    //   if (this.participant.virtual_roadahead_session) {
    //     this.selected_session_count++;
    //   }
    //   if (this.participant.fr_dpo_session) {
    //     this.selected_session_count++;
    //   }
    //   if (this.participant.fr_navigating_breach_session) {
    //     this.selected_session_count++;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.register {
  & label {
    font-weight: 500;
  }

  & .sessions {
    & .session {
      & .card {
        border-radius: 50px !important;
      }

      label {
        font-weight: 700;
      }
    }
  }
}
</style>
